/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo } from 'react';
import cx from 'classnames';
import Popup from 'reactjs-popup';
import Youtube from 'react-youtube';
import sidekickInit from '../../../utils/sidekick/init';
import { CardPropTypes } from '../ElementCardPropTypes';

import styles from './EmbeddedVideoCard.module.scss';
import PreviewButton from '../MediaCard/PreviewButton';
import { getYoutubeId } from '../../../utils/link';
import Image from '../../Image';
import getAriaLabel from '../../../utils/getAriaLabelText';

const processCard = (item) => {
  const { linkedContent, headerText, subhead, supportingText } = item;
  const content = linkedContent || {};
  const type = content._contentTypeId || '';

  switch (type) {
    case 'pageRecipe':
      return {
        headerText: headerText || content.title,
        subhead: subhead || content.difficulty
      };
    case 'pageBlog':
    case 'pageNews':
      return {
        headerText: headerText || content.title,
        subhead: subhead || content.byline
      };
    case 'pagePerson':
      return {
        headerText: headerText || content.fullName,
        subhead: subhead || content.title
      };
    default:
      return { headerText, subhead, supportingText };
  }
};

const VIDEO_EXTENSIONS = ['mp4', 'mov', 'wmv', 'flv', 'avi', 'webm'];

const EmbeddedVideoCard = (props) => {
  const {
    _id,
    _contentTypeId,
    internalTitle,
    className,
    url,
    media,
    ratioHorizontal,
    ratioVertical
  } = props;
  const [showPreview, setShowPreview] = useState(false);
  const { headerText, subhead } = processCard(props);
  const extension = url.split('.').pop().split(/#|\?/)[0] || 'jpg';
  const videoId = getYoutubeId(url);
  const isContentfulVideo = VIDEO_EXTENSIONS.indexOf(extension.toLowerCase()) > -1;
  const { sidekicker } = sidekickInit({ _id, _contentTypeId, internalTitle });
  const ariaText = getAriaLabel(headerText, subhead, url, media?.title && media.title);

  const thumbnailImage = useMemo(() => {
    if (media && media.url) {
      return media;
    }

    return { url: `https://img.youtube.com/vi/${videoId}/0.jpg`, width: 400, height: 400 };
  }, [url, media, videoId]);

  const onYoutubeReady = (event) => {
    event.target.playVideo();
  };

  const ratio = ratioHorizontal && ratioVertical ? `${ratioHorizontal}/${ratioVertical}` : '16/9';

  return (
    <>
      <div
        data-testid="EmbeddedVideoCard"
        className={cx(styles.card, className)}
        {...sidekicker('Embedded Video Card')}>
        <div
          role={headerText || subhead ? null : 'img'}
          aria-label={headerText || subhead ? null : media.description || media.title}
          className={styles.cardBody}
          style={{
            aspectRatio: ratio,
            display: 'flex',
            alignSelf: 'center',
            justifySelf: 'center'
          }}>
          <div className={styles.thumbnail}>
            <Image image={thumbnailImage} {...sidekicker('Media')} columns={6} />
          </div>
          <div className={styles.background} />
          <div className={styles.text}>
            <div className={styles.textBody}>
              <div
                className={styles.textBodyHeaderText}
                {...(headerText ? sidekicker('Header Text') : {})}>
                {headerText}
              </div>
              <div>
                <div className={styles.textBodySubhead} {...(subhead ? sidekicker('Subhead') : {})}>
                  {subhead}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <PreviewButton ariaText={ariaText} type="video" onClick={() => setShowPreview(true)} />
          </div>
        </div>
      </div>
      <Popup
        open={showPreview}
        overlayStyle={{ zIndex: 10100 }}
        closeOnDocumentClick
        onClose={() => setShowPreview(false)}
        lockScroll
        closeOnEscape>
        <div
          role="dialog"
          aria-modal="true"
          aria-label={`video of ${ariaText}`}
          className={styles.overlay}>
          <button
            aria-label="close video pop-up"
            className={styles.closeButton}
            type="button"
            onClick={() => setShowPreview(false)}>
            <span>×</span>
          </button>

          <div
            style={{
              padding: '1rem 1rem 0rem',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <div className={styles.overlayContainer}>
              {isContentfulVideo ? (
                <div
                  className={styles.iframe}
                  style={{
                    paddingTop: `${ratio * 100}%`
                  }}>
                  <video width="100%" height="100%" controls autoPlay>
                    <source src={url} />
                  </video>
                </div>
              ) : (
                <Youtube
                  className={styles.iframe}
                  style={{
                    paddingTop: `${ratio * 100}%`
                  }}
                  opts={{
                    playerVars: {
                      autoplay: 1
                    }
                  }}
                  videoId={videoId}
                  onReady={onYoutubeReady}
                />
              )}
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};

EmbeddedVideoCard.propTypes = CardPropTypes;
EmbeddedVideoCard.defaultProps = {
  url: '',
  image: null
};

export default EmbeddedVideoCard;
