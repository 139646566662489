const getAriaLabelText = (headerText, subhead, url, alt) => {
  if (headerText && subhead && url) {
    return `press enter to learn more about ${headerText.toLowerCase()} ${subhead.toLowerCase()}`;
  }
  if (headerText && subhead && !url) {
    return `${headerText.toLowerCase()} ${subhead.toLowerCase()}`;
  }
  if (headerText && !subhead) {
    return `${headerText.toLowerCase()}`;
  }
  if (!headerText && subhead) {
    return `${subhead.toLowerCase()}`;
  }
  if (!headerText && !subhead) {
    if (!url && alt) {
      return `image of ${alt}`;
    }
    if (url) {
      return `go to ${url}`;
    }
  }
  if (!headerText && !subhead && !url && !alt) {
    return ``;
  }
};

export default getAriaLabelText;
