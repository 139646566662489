export const isInternal = (url) => /^\/(?!\/)/.test(url);

export const isSubdomain = (url) => {
  const match = /^http(s){0,1}:\/\/[^.]+.([^/]+)/.exec(url);
  return match && match[2] === process.env.GATSBY_PRODUCTION_DOMAIN;
};

export const getYoutubeId = (str) => {
  if (!str) return null;

  if (str.includes('embed')) {
    const re = new RegExp(`youtube.com/embed/(.+)$`, 'gi');
    const match = re.exec(str);

    return match && match[1];
  }

  if (str.includes('watch')) {
    return new URL(str).searchParams.get('v');
  }

  return null;
};

export const getFinderUrl = (str) => {
  const re = new RegExp('^finder[:](.+?)$', 'gi');
  const match = re.exec(str);

  return match && match[1];
};

export const getJumpUrl = (str) => {
  const re = new RegExp('^internal[:](.+?)$', 'gi');
  const match = re.exec(str);

  return match && match[1];
};

export const shareLinks = {
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  twitter: 'https://twitter.com/intent/tweet',
  sms: 'sms:&body=',
  instagram: {
    deepLink: 'instagram://user?username=impossible_foods',
    link: 'http://instagram.com/impossible_foods'
  }
};

export const slugify = (text) =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

export const isIOs = () => {
  if (window && typeof window !== 'undefined') {
    const { userAgent } = window.navigator;
    return /iphone|ipad|ipod/.test(userAgent.toLowerCase());
  }
  return false;
};
