import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './PreviewButton.module.scss';

const PreviewButton = ({ type, onClick, ariaText }) => {
  const svgIcon = {
    video: (
      <svg
        aria-hidden
        focusable="false"
        className={cx(styles.playIcon, styles.mediaIcon)}
        imageRendering="auto"
        baseProfile="basic"
        version="1.1"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 23 25"
      >
        <path d="M0.500,0.124 L0.500,24.497 L22.375,12.311 L0.500,0.124 ZM15.995,12.311 L3.690,19.167 L3.690,5.456 L15.995,12.311 Z" />
      </svg>
    ),
    image: (
      <svg
        aria-hidden
        focusable="false"
        className={cx(styles.searchIcon, styles.mediaIcon)}
        imageRendering="auto"
        baseProfile="basic"
        version="1.1"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 61 60"
      >
        <path d="M52.965,46.025 C47.612,51.289 40.596,53.921 33.580,53.921 C27.700,53.921 21.833,52.046 16.932,48.349 L6.031,59.070 C5.400,59.691 4.569,60.000 3.737,60.000 C2.906,60.000 2.074,59.691 1.444,59.070 L0.930,58.564 C-0.332,57.324 -0.332,55.294 0.930,54.053 L11.831,43.332 C3.585,32.761 4.356,17.572 14.195,7.896 C19.548,2.632 26.564,-0.000 33.580,-0.000 C40.596,-0.000 47.612,2.632 52.965,7.896 C63.671,18.425 63.671,35.496 52.965,46.025 ZM48.378,12.408 C44.425,8.521 39.170,6.380 33.580,6.380 C27.990,6.380 22.735,8.521 18.783,12.408 C10.623,20.433 10.623,33.489 18.783,41.513 C22.735,45.400 27.990,47.541 33.580,47.541 C39.170,47.541 44.425,45.400 48.378,41.513 C56.537,33.489 56.537,20.433 48.378,12.408 Z" />
      </svg>
    )
  };

  return (
    <button
      type="button"
      className={styles.button}
      aria-label={`press enter to open pop-up ${type} about ${ariaText}`}
      onClick={onClick}
    >
      <div className={styles.content}>
        <svg
          aria-hidden
          focusable="false"
          className={styles.circle}
          imageRendering="auto"
          baseProfile="basic"
          version="1.1"
          preserveAspectRatio="xMidYMid meet"
          viewBox="25 25 50 50"
        >
          <circle
            className="loader-path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke="#000"
            strokeWidth="2"
          />
        </svg>
        {svgIcon[type]}
      </div>
    </button>
  );
};

PreviewButton.propTypes = {
  type: PropTypes.oneOf(['video', 'image']),
  onClick: PropTypes.func,
  ariaText: PropTypes.string
};

PreviewButton.defaultProps = {
  type: 'video',
  onClick: () => {},
  ariaText: ''
};

export default PreviewButton;
